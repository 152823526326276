import { createTheme } from "@mui/material";
export const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
            styleOverrides: {
                root: {
                    fontSize: "1.0rem",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: "1.0rem",
                    marginTop: "10px",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    color: "#63c3d1",
                    fontSize: "2rem",
                    fontWeight: 300,
                    padding: "2rem",
                },
                separator: {
                    fontSize: "2rem",
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: "h4" },
                    style: {
                        fontSize: "2.2em",
                    },
                },
            ],
        },
    },
});
