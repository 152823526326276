export const INSIDE_DRAWER = {
    // tables: {
    //     drawer_name: "Tablas",
    //     show_drawer: true,
    //     isOpen: false,
    // },
};

export const ROUTES = {
    login: {
        route: "/",
        showMenuItem: false,
        needSuperAuth: false,
    },
    home: {
        route: "/home",
        wordKey: "Dashboard",
        showMenuItem: false,
        needSuperAuth: true,
    },
};
