import { USER_ROL_CODS } from "consts/rolCods";
import { ROUTES } from "consts/routes";

const { RequestServiceGet } = require("../RequestServiceGet");

export async function UserRolGet(
    setSnackbarInfo,
    navigate,
    credentials,
    setCredentials
) {
    const body = {
        service: "UsuarioMIARolGet",
        params: {},
    };

    const result = await RequestServiceGet(body, credentials, setSnackbarInfo);

    if (!result.length) {
        return setSnackbarInfo({
            message: "No autorizado",
            severity: "error",
            open: true,
        });
    }

    if (result.length > 1) {
        let roles = [];

        for (let res in result) {
            roles.push(result[res].rolCod);
        }

        setCredentials({ ...credentials, rol: roles });

        return navigate(ROUTES.home.route);
    }

    const { aplicacionWebURL, rolCod } = result[0];

    setCredentials({ ...credentials, rol: rolCod });

    if (rolCod === USER_ROL_CODS.SUPER) return navigate(ROUTES.home.route);

    window.location.assign(
        `${aplicacionWebURL}?session=${credentials.sessionId}&miaToken=${credentials.miaToken}`
    );
}
