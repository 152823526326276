import { useRecoilValue } from "recoil";
import { userCredentials } from "recoil/GlobalState";

import { USER_ROL_CODS } from "consts/rolCods";

export default function ProtectedRoute({ isSuperAuth = false, children }) {
    const FALLBACK_URL = process.env.REACT_APP_FALLBACK_URL;

    const credentials = useRecoilValue(userCredentials);

    if (!credentials) window.location.assign(FALLBACK_URL);

    // check if rol is not super or less than one rol is assigned
    if (isSuperAuth && credentials.rol.length <= 1 && !credentials.rol.includes(USER_ROL_CODS.SUPER) || !credentials.rol) return window.location.assign(FALLBACK_URL);

    return children;
}