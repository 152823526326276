import { REACT_APP_FALLBACK_URL } from "consts/env";

export const checkAuth = () => {
    if (localStorage.getItem("miaToken") && localStorage.getItem("sessionId")) {
        return {
            miaToken: localStorage.getItem("miaToken"),
            sessionId: localStorage.getItem("sessionId"),
        };
    }

    localStorage.clear();
    return window.location.assign(REACT_APP_FALLBACK_URL);
};
