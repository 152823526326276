import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

import LogoQR from "assets/icons/QR.png";

import CircularIndeterminate from "components/UI/Spinner/Spinner";

import { LinearProgress } from "@material-ui/core";

import {
    LoginQRGet,
    LoginQREvent,
    usuarioMinimosGet,
} from "services/mia-services";
import { UserRolGet } from "services/mia-services/Login/UserRolGet";

import { userCredentials, snackbarData, userInfo } from "recoil/GlobalState";
import { useRecoilState, useSetRecoilState } from "recoil";

import styles from "styles/pages/Login.module.scss";

export default function Login() {
    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(false);
    const [expired, setExpired] = useState(false);
    const [coord, setCoord] = useState({});
    const [progress, setProgress] = useState(0);

    const navigate = useNavigate();

    const [credentials, setCredentials] = useRecoilState(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setLoggedUserInfo = useSetRecoilState(userInfo);

    const intentos = useRef(0);

    async function getQR(e) {
        if (e) e.preventDefault();

        setExpired(false);
        setLoading(true);

        const data = await LoginQRGet(
            {
                lat: coord.lat.toFixed(6),
                lng: coord.lng.toFixed(6),
            },
            setSnackbarInfo
        );

        setProgress(0);
        setUUID(data.codigoQR);
        setLoading(false);
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            () =>
                setSnackbarInfo({
                    message: "Error de localización",
                    open: true,
                    severity: "error",
                })
        );

        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 60 / 580 >= 100
                    ? 100
                    : oldProgress + ((60 / 580) * 100) / 60
            );
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (coord.lat && coord.lng) getQR();
    }, [coord]);

    useEffect(() => {
        if (UUID) {
            (async function getQREvent() {
                const data = await LoginQREvent({ qr: UUID }, setSnackbarInfo);

                if (data.status.code === 0) {
                    intentos.current++;

                    setUUID(null);

                    if (intentos.current > 2) {
                        setExpired(true);
                    } else {
                        getQR();
                    }
                }

                if (data.status.code === 1) {
                    setCredentials({ sessionId: data.result[0].sessionId, miaToken: data.result[0].hooliToken });

                    localStorage.setItem("miaToken", data.result[0].hooliToken);
                    localStorage.setItem("sessionId", data.result[0].sessionId);
                }
            })();
        }
    }, [UUID]);

    useEffect(() => {
        // returns user name and avatar
        async function retrieveUsuarioMinimo() {
            const data = await usuarioMinimosGet(credentials, setSnackbarInfo);

            if (data) setLoggedUserInfo(data);
        }

        if (Object.keys(credentials).length > 0) {
            UserRolGet(setSnackbarInfo, navigate, credentials, setCredentials);

            retrieveUsuarioMinimo();
        }
    }, [credentials]);

    return (
        <div className={styles.login}>
            <div className={styles.container}>
                <div className={styles.qrContainer}>
                    {expired ? (
                        <>
                            <h4>Ups!</h4>
                            <p>
                                El código caducó, generá un{" "}
                                <span>nuevo QR</span> para poder ingresar.
                            </p>
                            <img src={LogoQR} alt="Celular" />
                            <button className={styles.button} onClick={getQR}>
                                Generar QR
                            </button>
                        </>
                    ) : (
                        <>
                            {!loading ? (
                                <>
                                    <h4>Hola!</h4>
                                    <p>
                                        Para poder ingresar, escanea el{" "}
                                        <span>QR</span> desde la App de MIA.
                                    </p>
                                </>
                            ) : null}
                            {UUID && !loading && (
                                <>
                                    <QRCode value={UUID} />
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                        style={{
                                            width: "100%",
                                            marginTop: "16px",
                                        }}
                                    />
                                </>
                            )}
                            {loading && <CircularIndeterminate />}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
