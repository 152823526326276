import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { List, ListItem, ListItemIcon } from "@material-ui/core";

import { InsideDrawerNav } from "./InsideDrawerNav";

import IconTables from "assets/icons/icon-tables.svg";

import { INSIDE_DRAWER } from "consts/routes";

import { useRecoilValue } from "recoil";
import { routeInfo, userCredentials } from "recoil/GlobalState";

import { ListDrawerStyles } from "styles/components/List/ListDrawer.styles";

export default function ListDrawer() {
    const styles = ListDrawerStyles();

    const [availableRoutes, setAvailableRoutes] = useState([]);

    const routes = useRecoilValue(routeInfo);
    const { miaToken, sessionId } = useRecoilValue(userCredentials);

    function getRoutes() {
        /* Sorting the routes alphabetically. */
        let partialRoutes = [];
        let finalRoutes = {};

        for (let route in routes) {
            partialRoutes.push([route, routes[route]]);
        }

        partialRoutes.sort(function (a, b) {
            if (a[1].aplicacionWebNombre < b[1].aplicacionWebNombre) {
                return -1;
            }

            if (a[1].aplicacionWebNombre > b[1].aplicacionWebNombre) {
                return 1;
            }

            return 0;
        });

        partialRoutes.forEach(function (item) {
            finalRoutes[item[1].aplicacionWebNombre] = item[1];
        });

        setAvailableRoutes(finalRoutes);
    }

    useEffect(() => {
        getRoutes();
    }, [routes]);

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={styles.root}
            >
                {Object.values(availableRoutes).map(
                    (route) =>
                    (
                        <ListItem
                            button
                            className={styles.itemList}
                            key={route.aplicacionWebId}
                            onClick={() => window.location.assign(
                                `${route.aplicacionWebURL}?session=${sessionId}&miaToken=${miaToken}`
                            )}
                        >
                            <ListItemIcon>
                                <img
                                    className={styles.iconsDrawer}
                                    src={IconTables}
                                    alt="Tables"
                                />
                            </ListItemIcon>

                            <span className={styles.textColor}>
                                {route.aplicacionWebNombre}
                            </span>
                        </ListItem>
                    )
                )}
            </List >

            {/* Onboarding */}
            {
                Object.values(INSIDE_DRAWER).map((drawer, index) => (
                    <InsideDrawerNav
                        availableRoutes={availableRoutes}
                        drawer={drawer}
                        key={index}
                    />
                ))
            }
        </>
    );
}
