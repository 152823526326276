import axiosPost from "services/mia-services/axiosPost";

export const LoginQRGet = async (arg, setSnackbarInfo = () => {}) => {
    const body = {
        service: "LoginQRGet",
        params: {
            sesionLatitud: arg.lat,
            sesionLongitud: arg.lng,
        },
    };

    try {
        const result = await axiosPost("Y", body, arg);

        if (result?.status?.errcode) {
            setSnackbarInfo({
                message: result.status.errmsg,
                severity: "error",
                open: true,
            });
            setTimeout(() => {
                setSnackbarInfo({ open: false });
            }, 2500);
        }

        return result.result[0];
    } catch (e) {
        setSnackbarInfo({
            message: e.errmsg,
            severity: "error",
            open: true,
        });
        setTimeout(() => {
            setSnackbarInfo((data) => !data.open);
        }, 1500);
    }
};
