import axiosPost from "services/mia-services/axiosPost";

export const LoginQREvent = async (arg, setSnackbarInfo = () => {}) => {
    const body = {
        service: "LoginQREvent",
        params: {
            codigoQR: arg.qr,
        },
    };

    try {
        const result = await axiosPost("Y", body, arg);

        if (result?.status?.errcode) {
            setSnackbarInfo({
                message: result.status.errmsg,
                severity: "error",
                open: true,
            });
        }

        return result;
    } catch (e) {
        setSnackbarInfo({
            message: e.errmsg,
            severity: "error",
            open: true,
        });
    }

    try {
        const result = await axiosPost("N", body, arg);

        return result;
    } catch (err) {
        console.error(err);
    }
};
