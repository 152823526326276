import { makeStyles } from "@material-ui/core/styles";

export const SpinnerStyles = makeStyles(() => ({
    container: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
    },
    root: {
        fontSize: 60,
    },
}));
