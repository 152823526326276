import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { SnackBar, ProtectedRoute, Header } from "components/index";

import { Login, Dashboard } from "pages/index";

import { ROUTES } from "consts/routes";

import { checkAuth } from "services/mia-services";

import { useSetRecoilState } from "recoil";
import { userCredentials, snackbarData } from "recoil/GlobalState";

import "styles/global.css";

export default function App() {
    const setCredentials = useSetRecoilState(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    useEffect(() => {
        // redirect to login if not credentials && set development token
        if (window.location.pathname !== ROUTES.login.route) {
            setCredentials(checkAuth());
        }
    }, [setCredentials, setSnackbarInfo]);

    return (
        <Router>
            <SnackBar />
            <Routes>
                {/* Login */}
                <Route path={ROUTES.login.route} exact element={<Login />} />

                {/* Home */}
                <Route
                    path={ROUTES.home.route}
                    exact
                    element={
                        <ProtectedRoute>
                            <Header />
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}
