import { useEffect } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, snackbarData, userCredentials } from "recoil/GlobalState";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

const Dashboard = () => {
    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setRoutes = useSetRecoilState(routeInfo);

    async function getUrls() {
        const body = {
            service: 'AplicacionWebGet',
            params: {}
        };

        const result = await RequestServiceGet(body, credentials, setSnackbarInfo);

        setRoutes(result);
    }

    useEffect(() => {
        getUrls();
    }, []);

    return;
};

export default Dashboard;
