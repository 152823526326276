import { atom } from "recoil";
import AvatarUser from "assets/img/avatar2.png";

export const userInfo = atom({
    key: "userInfo",
    default: {
        usuarioNombres: "Backuser",
        usuarioApellidos: "",
        imagePathPERFIL: AvatarUser,
    },
});

export const userCredentials = atom({
    key: "userCredentials",
    default: {},
});

export const routeInfo = atom({
    key: "routeInfo",
    default: {},
});

export const snackbarData = atom({
    key: "snackbarData",
    default: {
        open: false,
    },
});
