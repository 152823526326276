import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { SpinnerStyles } from "styles/components/UI/Spinner.styles";

export default function CircularIndeterminate({ size = 40 }) {
    const styles = SpinnerStyles();
    return (
        <div className={styles.container}>
            <CircularProgress className={styles.root} size={size} />
        </div>
    );
}
