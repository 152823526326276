import React, { useEffect } from "react";
// Style
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { SnackbarStyles } from "styles/components/UI/Snackbar.styles";
// Recoil
import { useRecoilState } from "recoil";
import { snackbarData } from "recoil/GlobalState";

export default function SnackBar() {
    const [{ message, severity, open }, setSnackbarData] =
        useRecoilState(snackbarData);

    const styles = SnackbarStyles();

    useEffect(() => {
        setTimeout(() => {
            setSnackbarData({ open: false });
        }, 2500);
    }, [open]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            className={styles.root}
            key="top-center"
            open={open}
        >
            <Alert
                className={styles.alert}
                color={severity}
                elevation={6}
                severity={severity}
            >
                <span className={styles.message}>{message}</span>
            </Alert>
        </Snackbar>
    );
}
