import React, { useState } from "react";
import { Link } from "react-router-dom";

import { List, ListItem, ListItemIcon } from "@mui/material";
import { Collapse } from "@material-ui/core";

import { ArrowDown, IconTables } from "assets/icons";

import { ListDrawerStyles } from "styles/components/List/ListDrawer.styles";

export const InsideDrawerNav = ({ availableRoutes, handleClick, drawer }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const styles = ListDrawerStyles();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={styles.root}
        >
            <ListItem
                className={styles.itemList}
                button
                onClick={() => setIsDrawerOpen((isDrawerOpen) => !isDrawerOpen)}
            >
                <ListItemIcon>
                    <img
                        alt={drawer.drawer_name}
                        className={styles.iconsDrawer}
                        src={IconTables}
                    />
                </ListItemIcon>
                <span className={styles.textColor}>{drawer.drawer_name}</span>
                <img alt="arrow-down" className="icon-expand" src={ArrowDown} />
            </ListItem>

            <Collapse in={isDrawerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(availableRoutes).map(
                        (route) =>
                            route.showMenuItem &&
                            route.insideDrawer === drawer.drawer_name && (
                                <Link
                                    className={styles.link}
                                    key={route.route}
                                    to={route.route}
                                >
                                    <ListItem
                                        button
                                        className={styles.nested}
                                        onClick={() => handleClick(route)}
                                    >
                                        <span className={styles.textColor}>
                                            {route.wordKey}
                                        </span>
                                    </ListItem>
                                </Link>
                            )
                    )}
                </List>
            </Collapse>
        </List>
    );
};
